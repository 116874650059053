import React, { useState } from 'react';
import Anchor from 'antd/lib/anchor'
import Link from 'antd/lib/anchor/AnchorLink'

// const { Link } = Anchor;

function AppHeader() {
  const [visible, setVisible] = useState(false);

  // const access_token = localStorage.getItem('access_token');
  const user =  JSON.parse(localStorage.getItem('user'))



  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="container-fluid">
      <div className="header">
        <div className="logo">
          <i className="fas fa-bolt"></i>
          <a href="/">Writers Horizon</a>
        </div>
        <div className="mobileHidden">
          <Anchor targetOffset="65">
            <Link className="header-link" href="https://writershorizon.com//how-it-works" title="How it works" />
            <Link className="header-link" href="https://writershorizon.com/blog" title="Blog" />
            {
              user == null ? <Link className="header-link" href="/login" title="Login/Sign Up" />: <Link className="header-link" title="Log out" href='/logout'/>
            }
          </Anchor>
        </div>
      </div>
    </div>
  );
}

export default AppHeader;