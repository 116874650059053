import React,{useEffect,useState,useContext} from 'react'
import { useParams} from 'react-router-dom';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Row from 'antd/lib/row';
import message from "antd/lib/message";
import Col from 'antd/lib/col';
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'

import parse from 'html-react-parser';
import { baseUrl } from "../Config";

import { axiosAuthenticated,currencyFormatter} from "../helper";
import OrderAssignmentModal from './OrderAssignmentModal';
import { ModalContext} from "../context/ModalContext";
import CancelOrderModal from './CancelOrderModal';
import OrderUploadModal from './OrderUploadModal';

const OrderItem = () => {
    const {assignModalVisibility} = useContext(ModalContext)
    const { id } = useParams();
    const [order,setOrder] = useState(null)

    const [isVisible,setVisible] = assignModalVisibility

    const [cancelVisible,setCancelVisible] = useState()
    const [cancelLoading,setCancelLoading] = useState()
    const [uploadVisible,setUploadVisible] = useState(false);

    const handleUploadCancel = () => {
        setUploadVisible(false)
    }
    const handleCancelCancel = () =>{
        setCancelVisible(false)
    }
    const handleCancelOk = () => {
        setCancelLoading(true)
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/order/revoke`,
            method:'post',
            data:{
                'order_id':id
            }
        }).then((res)=>{
            if(res.data.status == true){
                message.success("Order cancelled")
                window.location.reload(); 

            }else{
                message.error("failed to cancel order")
            }
            setCancelLoading(false)
            setCancelVisible(false)
        },(err)=>{
            message.error("failed to cancel order")
            setCancelLoading(false)
            setCancelVisible(false)
        })
    }

    useEffect(()=>{
        const fetchOrder = async () => {
            await axiosAuthenticated()({
                method: "get",
                url: `${baseUrl}/api/v1/admin/order/list/${id}`,
            }).then(
                (response)=>{
                    console.log(response.data.data)
                   setOrder(response.data.data)
                },
                (error)=>{
                    console.log(error)
                })
        }
        fetchOrder()
    },[])


    return (
        
        <div className="site-card-wrapper block container-fluid" style={{marginTop:'50px',alignItems:'center',justifyContent:'center',width:'100vh'}}>
            {order != null &&
            <><Card key={order.id} bordered={true} style={{marginBottom:5}}>
                <Title level={4} >{order.title}</Title>
                <Row justify='space-between'>
                    <Col>
                        <b><Text>Deadline: {order.deadline}</Text></b><br></br>
                        <b><small><Text>Include Keywords: {order.keywords}</Text></small></b>
                    </Col>
                    <Col>
                        <b><Text style={{fontSize:24,color:'blue'}}>{currencyFormatter.format(order.budget)}</Text></b>
                    </Col>
                </Row>
                { order.description !== undefined && parse(order.description)}
                <br></br>
                { order.assigned === 1 || order.assigned === 5 ? <Button disabled onClick={()=>setVisible(true)}>Assign Writer</Button>
                 :  <Button onClick={()=>setVisible(true)}>Assign Writer</Button>}
                { order.assigned === 1 && <Button onClick={()=>setCancelVisible(true)}>Revoke Asignment</Button>}
                { order.assigned === 1 && <Button onClick={()=>setUploadVisible(true)}>Submit Order</Button>}
            </Card>
            <OrderAssignmentModal orderId={id} />
            <CancelOrderModal isLoading={cancelLoading} isVisible={cancelVisible} 
            handleOk={handleCancelOk} handleCancel={handleCancelCancel}/>
            <OrderUploadModal visible={uploadVisible} handleSubmitCancel={handleUploadCancel} orderId={id}/>
            </>
            }
        </div>
    )
}

export default OrderItem

