import React,{useState} from 'react'
import { useAdminFetch } from "../hooks";
import { axiosAuthenticated } from '../helper';

import Button from 'antd/lib/button'
import Title from 'antd/lib/typography/Title';
import Table from 'antd/lib/table';
import message from 'antd/lib/message';

import DeleteAdminModal from './DeleteAdminModal';
const AdminUsers = () => {
    const [isLoading_,setIsLoading] = useState(false)
    const [isVisible,setIsVisible] = useState(false)
    const [userId,setUserId] = useState()

    const { response, error, isLoading } = useAdminFetch({
        method:'get',
        url:'/api/v1/admin/user/list'
    })

    const handleOk = () => {
        setIsLoading(true)
       axiosAuthenticated()({
           url:'/api/v1/admin/user/delete',
           method:'delete',
           data:{
               id: userId
           }
       }).then((res)=>{
           if (res.data.status = true) {
               message.success("User deleted")
               setIsVisible(false)
               window.location.reload();
           }else{
               message.error("failed to delete user")
           }
           setIsLoading(false)
       },
       (err)=>{
            message.error("failed to delete user")
            setIsLoading(false)
       })
    }

    const handleCancel = () => {
        setIsVisible(false)
    }
  

    const columns = [
        {
          title: 'Order Id',
          dataIndex: 'id',
          key: 'id',
          render: text => <a>#{text}</a>,
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'email',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text,record) => <Button danger onClick={() => {setIsVisible(true); setUserId(record.id)}}>
                <a target='_blank'>DELETE</a>
            </Button>,
        }]

    return (
        <div className="site-card-wrapper block container-fluid" style={{display:'flex',flexDirection:'column',marginTop:'50px',alignItems:'center',justifyContent:'center',marginLeft:5,marginRight:5}}>
            <Title level={2}>Admins</Title>
            {!isLoading && <Table columns={columns} dataSource={response}/>}
            <DeleteAdminModal isLoading={isLoading_} isVisible={isVisible} handleCancel={handleCancel} handleOk={handleOk}/>
        </div>
    )
}

export default AdminUsers
