import React,{useState} from 'react'
import OrderSubmitView from './UploadOrder';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Title from 'antd/lib/typography/Title'

function OrderUploadModal({visible,handleSubmitCancel,orderId}) {

    const [fileList,setFileList]  = useState([])
    const [uploading,setUploading] = useState(false)
    
    return (
        <div>
             <Modal
                visible={visible}
                // onOk={handleUpload}
                onCancel={handleSubmitCancel}
                footer={[
                    <Button type='primary' key="back" onClick={handleSubmitCancel}>
                        No. Go back.
                    </Button>,
                    // <Button key="submit" type="primary" loading={false} onClick={handleUpload}>
                    //     Upload Order
                    // </Button>,
                ]}>
                <div>
                    <Title level={3}>Are you sure to upload order? </Title>
                    <OrderSubmitView orderId={orderId}/>
                </div>
            </Modal>   
        </div>
    )
}
export default OrderUploadModal
