import React,{useState} from 'react'
import Moment from 'react-moment';
import axios from 'axios'
import { baseUrl } from '../Config';

import Table from 'antd/lib/table'
import Space from 'antd/lib/space'

const OrdersTable = ({data}) => {
  
    const [orderFile,setOrderFile] = useState()

    //#################################################
    const access_token = localStorage.getItem('access_token')
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`

    const downloadFile = () => {
      axios({
        method:'get',
        url:`/api/v1/business/order/file/${orderFile}`
      }).then((response)=>{
        const file_name = response.data.data[0].file_name
        //console.log(response.data.data[0].file_name)
        window.open(`${baseUrl}/storage/orders/${file_name}`, '_blank');
      },
      (err)=>{

      })
    }

    data = data.map((item,index) => {
      let o = Object.assign({}, item);
      o.key = index
      return o
    })
    
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: text => <a>{text}</a>,
      },
      {
        title: 'Created At',
        key: 'created_at',
        render: (text, record) => (
          <Space size="middle">
            <Moment format="YYYY/MM/DD">
              {record.created_at}
            </Moment>
          </Space>
        ),
      },
      {
        title: 'Deadline',
        key: 'deadline',
        render: (text, record) => (
          <Space size="middle">
            <Moment format="YYYY/MM/DD">
              {record.deadline}
            </Moment>
          </Space>
        ),
      },
      {
        title: 'status',
        key: 'assigned',
        render: (text, record) => (
          <Space size="middle">
            {(() => {
              switch (record.assigned) {
                case 0:return <a>unassigned</a>
                case 1: return <a>assigned</a>
                case 2: return <a>in review</a>
                case 4: return (<><a>waiting client reply</a> <a onClick={()=>{setOrderFile(record.id); console.log(orderFile); downloadFile()}}>Download submission</a></>)
                case 5: return (<><a>finished</a> <a onClick={()=>{setOrderFile(record.id); console.log(orderFile); downloadFile()}}>Download submission</a></>)
                default: return <a>unassigned</a>
              }
            })()}
          </Space>
        ),
      },
    ];
    return (
        <div className="container-fluid" style={{padding:15}}>
          <Table columns={columns} dataSource={data} /> 
        </div>
    )
  }

  export default OrdersTable
