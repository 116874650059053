import React,{useEffect,useState} from 'react';
import axios from 'axios'
import {Link} from 'react-router-dom'

import Button from 'antd/lib/button'
import List from 'antd/lib/list';
import Card from 'antd/lib/card';

import { baseUrl } from "../Config";

function ListPricing() {

  const [plans,setPlans] = useState([])
  const access_token = localStorage.getItem('access_token')
  axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
  
  useEffect(()=>{
    const fetchPlans = async () => {
        await axios({
            method: 'get',
            url: `${baseUrl}/api/v1/admin/pricing`,
        }).then(
            (response) =>{
                const result = response.data.data
                //console.log(result)
                setPlans(result)
            },
            (error)=>{
                console.log(error)
            }
        )
    }
    fetchPlans()
  },[])

return (
    <div id="pricing" className="block pricingBlock bgGray">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2 className="price-tag">Pricing plans</h2>
        </div>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={plans}
          renderItem={item => (
            <List.Item>
              <Card title={item.title}>
                <p className="large">${item.price }</p>
                <p>{item.description}</p>
                <Link style={{margin:15}} to={`/pricing/edit/${item.id}`}><Button type="primary" size="large"><i className="fab fa-telegram-plane"></i>edit</Button></Link>
                <Link to={`/pricing/delete/${item.id}`}><Button danger type="primary" size="large"><i className="fab fa-telegram-plane"></i>delete</Button></Link>

              </Card>
            </List.Item>
          )}
        />
      </div>
    </div>  
  );
}

export default ListPricing;