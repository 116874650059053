import React,{useState,useEffect}from 'react'

import Table from 'antd/lib/table'
import message from 'antd/lib/message'
import Space from 'antd/lib/space'
import Moment from 'react-moment';
import axios from 'axios';
import {baseUrl} from '../Config'

const ListAgencies = () => {
    const [agencies,setAgencies] = useState()

    const access_token = localStorage.getItem('access_token')
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
    
    useEffect(()=>{
        axios({
            url:`${baseUrl}/api/v1/admin/agencies/list`,
            method:'get'
        }).then((res)=>{
            if(res.data.status == true){
                setAgencies(res.data.data)
            }else{
                message.error("failed to load writers")
            }
        },(err)=>{
            message.error("failed to load writers")
        })
    },[])

    const columns = [
        {
          title: 'Name',
          key: 'name',
          render: (text, record) => (
            <Space size="middle">
              <a href={`/agency/orders/${record.id}`}>{record.name}</a>
            </Space>
          ),
        },
        {
            title: 'Phone',
            key: 'phone',
            render: (text, record) => (
              <Space size="middle">
               {record.phone}
              </Space>
            ),
        },
        {
            title: 'Email',
            key: 'email',
            render: (text, record) => (
              <Space size="middle">
                {record.email}
              </Space>
            ),
        },
        {
          title: 'Registration',
          key: 'deadline',
          render: (text, record) => (
            <Space size="middle">
              <Moment format="YYYY/MM/DD">
                {record.created_at}
              </Moment>
            </Space>
          ),
        },
      ];
    return (
        <div className="container-fluid" style={{justifyContent:'center',marginTop:80}}>
            <h2 style={{margin:20}}>Agencies list</h2>
            <Table columns={columns} dataSource={agencies} /> 
        </div>
    )
}

export default ListAgencies
