import React,{useState,useEffect} from 'react'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import message from 'antd/lib/message'

import {axiosAuthenticated} from '../helper'
import {baseUrl} from '../Config'


const SetEmailConfigs = () => {
    const [adminMail,setAdminMail] = useState(null)
    const [orderMail,setOrderMail] = useState(null)
    const [accountsMail,setAccountsMail] = useState(null)

    const [admId,setAdmId] = useState()
    const [orderId,setOrdId] = useState()
    const [accId,setAccId] = useState()

    const [adminLoading,setAdminLoading] = useState(false)
    const [orderLoading,setOrderLoading] = useState(false)
    const [accountsLoading,setAccoountsLoading] = useState(false)

    useEffect(()=>{
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/email/type`,
            method:'post',
            data:{
                "type":"admin_ops"
            }
        }).then((res)=>{
            if(res.data.status == true){
                setAdminMail(res.data.data[0].email)
                setAdmId(res.data.data[0].id)
            }

        },(err)=>{
    
        })

        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/email/type`,
            method:'post',
            data:{
                "type":"accounts_ops"
            }
        }).then((res)=>{
            if(res.data.status == true){
                setAccountsMail(res.data.data[0].email)
                setAccId(res.data.data[0].id)
            }
        },(err)=>{
    
        })

        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/email/type`,
            method:'post',
            data:{
                "type":"order_ops"
            }
        }).then((res)=>{
            if(res.data.status == true){
                setOrderMail(res.data.data[0].email)
                setOrdId(res.data.data[0].id)
            }
        },(err)=>{
    
        })
    },[])

    const handleAdminSubmit = () => {
        setAdminLoading(true)
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/email/configs/${admId}`,
            method:'post',
            data:{
                "email": adminMail
            }
        }).then((res)=>{
            setAdminLoading(false)
            if(res.data.status == true){
                message.success("Email updated")
            }else{
                message.error("failed to update")
            }
        },(err)=>{
            setAdminLoading(false)
            message.error("failed to update")
        })

    }

    const handleAccountSubmit = () => {
        setAccoountsLoading(true)
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/email/configs/${accId}`,
            method:'post',
            data:{
                "email": accountsMail
            }
        }).then((res)=>{
            setAccoountsLoading(false)
            if(res.data.status == true){
                message.success("Email updated")
            }else{
                message.error("failed to update")
            }
        },(err)=>{
            setAccoountsLoading(false)
            message.error("failed to update")
        })
    }

    const handleOrderSubmit = () => {
        setOrderLoading(true)
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/email/configs/${orderId}`,
            method:'post',
            data:{
                "email": orderMail
            }
        }).then((res)=>{
            setOrderLoading(false)
            if(res.data.status == true){
                message.success("Email updated")
            }else{
                message.error("failed to update")
            }
            
        },(err)=>{
            setOrderLoading(false)
            message.error("failed to update")
        })
    }
    return (
        <div className="site-card-wrapper block container-fluid" style={{marginTop:'50px',alignItems:'center',justifyContent:'center',width:'100vh'}}>
             <h2>Update Email Configurations</h2>
             { adminMail && orderMail && accountsMail && <> <Form
                name="normal_form"
                className="normal-form"
                initialValues={{ remember: true,admin:adminMail}}
                >
                <Form.Item
                    name="admin"
                    label="Set admin communication email"
                    rules={[
                    { 
                        required: true,
                        message: 'Please communication email' 
                    }]
                    }
                >
                    <Input placeholder="Please communication emails" onChange={(e)=>setAdminMail(e.target.value)}/>
                </Form.Item> 
                <Button key="submit" type="primary" loading={adminLoading} onClick={handleAdminSubmit}>
                    Update Admin Email
                </Button>
            </Form>
             <Form
                name="normal_form"
                className="normal-form"
                initialValues={{ remember: true,order: orderMail}}
                >
                <Form.Item
                    name="order"
                    label="Set order/sales communication email"
                    rules={[
                    { 
                        required: true,
                        message: 'Set order/sales communication email' 
                    }]
                    }
                >
                    <Input placeholder="Set order/sales communication email" onChange={(e)=>setOrderMail(e.target.value)}/>
                </Form.Item> 
                <Button key="submit" type="primary" loading={orderLoading} onClick={handleOrderSubmit}>
                    Update Order Email
                </Button>
            </Form>
             <Form
                name="normal_form"
                className="normal-form"
                initialValues={{ remember: true,account: accountsMail}}
                >
                <Form.Item
                    name="account"
                    label="Set accounts communication email"
                    rules={[
                    { 
                        required: true,
                        message: 'Set accounts communication email' 
                    }]
                    } >
                    <Input placeholder="Set accounts communication email" onChange={(e)=>setAccountsMail(e.target.value)}/>
                </Form.Item> 
                <Button key="submit" type="primary" loading={accountsLoading} onClick={handleAccountSubmit}>
                    Update Accounts Email
                </Button>
            </Form>
            </>
            }
        </div>
    )
}

export default SetEmailConfigs