import React,{createContext,useState} from 'react'

export const ModalContext = createContext()


//include state in the context
export const ModalProvider = (props) => {

    const [isVisible,setVisible] = useState(false)

    return (
       <ModalContext.Provider value={{
            assignModalVisibility: [isVisible,setVisible],
           }}>
               
           {props.children}

       </ModalContext.Provider>
    )
}