import React,{useEffect,useState} from 'react';
import {axiosAuthenticated} from '../helper';
import { baseUrl } from "../Config";
import {useParams} from 'react-router-dom';
import OrdersTable from './OrdersTable';
import message from 'antd/lib/message'

const AgencyOrders = () => {
    const {id }  = useParams();
    const [orders,setOrders] = useState(null);
    useEffect(() => {
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/agency/orders/${id}`,
            method:'get'
        }).then((res)=>{
            if(res.data.status = true){
                setOrders(res.data.data)
                console.log(res.data.data)
            }else{
                message.error('Failed to load orders')
            }
        },(err)=>{
            message.error('Failed to load orders')
        })
    }, [])
    return (
        <div className="container-fluid" style={{justifyContent:'center',marginTop:80}}>
            <h2>Agency orders</h2>
            {orders != null && <OrdersTable data={orders}/>}
        </div>
    )
}

export default AgencyOrders
