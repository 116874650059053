import React from 'react';
import Menu from 'antd/lib/menu';
import SubMenu from 'antd/lib/menu/SubMenu';

import Switch from 'antd/lib/switch';

import MailOutlined from "@ant-design/icons/MailOutlined";
import PoundCircleOutlined from "@ant-design/icons/PoundCircleOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import VerifiedOutlined from "@ant-design/icons/VerifiedOutlined";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";


export default class Sider extends React.Component {
  state = {
    theme: 'dark',
    current: '1',
  };

  changeTheme = value => {
    this.setState({
      theme: value ? 'dark' : 'light',
    });
  };

  handleClick = e => {
    console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };

  render() {
    return (
      <div style={{marginTop:'25px'}}>
        <Switch
          checked={this.state.theme === 'dark'}
          onChange={this.changeTheme}
          checkedChildren="Dark"
          unCheckedChildren="Light"
        />
        <br />
        <br />
        <Menu
          theme={this.state.theme}
          onClick={this.handleClick}
          style={{ width: 'auto',minHeight:800 }}
          defaultOpenKeys={['sub1']}
          selectedKeys={[this.state.current]}
          mode="inline"
        >
          <SubMenu key="sub1" icon={<MailOutlined />} title="Manage Content">

            <Menu.Item key="90">
               <a href="/admin">Order Submissions</a>
            </Menu.Item>
           
            <Menu.Item key="1065">
               <a href="/orders/history">Orders</a>
            </Menu.Item>
           
            <Menu.Item key="96">
               <a href="/users">Users</a>
            </Menu.Item>
            <Menu.Item key="97">
               <a href="/user/add">Add Admin</a>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<PoundCircleOutlined />} title="Pricing">
            <Menu.Item key="5"><a href="/pricing">Pricing</a></Menu.Item>
            <Menu.Item key="6"><a href="/pricing/add">Add Price</a></Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" icon={<SettingOutlined />} title="Writers">
            <Menu.Item key="9"><a href="/writers">writers</a></Menu.Item>
            <Menu.Item key="10"><a href="/agencies">agencies</a></Menu.Item>
            <Menu.Item key="11"><a href="/businesses">businesses</a></Menu.Item>
          </SubMenu>
          <SubMenu key="sub5" icon={<UserOutlined/>} title="Testimonials">
            <Menu.Item key="13"><a href="/testimonial">list testimonial</a></Menu.Item>
            <Menu.Item key="69"><a href="/testimonial/unapproved">list unapproved/testimonial</a></Menu.Item>
            <Menu.Item key="14"><a href="/testimonial/add">Add testimonials</a></Menu.Item>
          </SubMenu>
          <SubMenu key="sub6" icon={<CheckCircleOutlined />} title="Payments">
            <Menu.Item key="15"><a href="/payments">Unsettled Payments</a></Menu.Item>
            <Menu.Item key="16"><a href="/payments/settled">Settled Payments</a></Menu.Item>
          </SubMenu>
          <SubMenu key="sub7" icon={<VerifiedOutlined />} title="Settings">
            <Menu.Item key="17"><a href="/settings/sitename">Site Name</a></Menu.Item>
            <Menu.Item key="19"><a href="/settings/email">Email Configs</a></Menu.Item>

          </SubMenu>
          <SubMenu key="sub8" icon={<VerifiedOutlined />} title="Paypal">
            <Menu.Item key="18"><a href="/paypal">Paypal Configs</a></Menu.Item>
          </SubMenu>
          <SubMenu key="sub9" icon={<VerifiedOutlined />} title="Reports">
            <Menu.Item key="21"><a href="/statistics">Statitistics</a></Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    );
  }
}