import React,{useState,useEffect} from 'react'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import message from 'antd/lib/message'

import {axiosAuthenticated} from '../helper'
import {baseUrl} from '../Config'

const PaypalConfigs = () => {
    const [clientId,setClientId] = useState(null)
    const [secretId,setSecretId] = useState(null)
    const [isLoading,setIsLoading] = useState(false)

    useEffect(()=>{
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/paypal/config`,
            method:'get'
        }).then((res)=>{
            const data = res.data.data
            setClientId(data.client_id)
            setSecretId(data.secret_id)
        },(err)=>{

        })
    },[])

    const handleSubmit = () => {

        if(secretId === null || clientId === null){
            return
        }
        setIsLoading(true)
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/paypal/config`,
            method:'post',
            data: {
                'client_id': clientId,
                'secret_id': secretId
            }
        }).then((res)=>{
            setIsLoading(false)
            message.success("keys updated")
        },(err)=>{
            setIsLoading(false)
            message.error("Failed to update keys")
        });
    }

    return (
        <div className="site-card-wrapper block container-fluid" style={{marginTop:'50px',alignItems:'center',justifyContent:'center',width:'100vh'}}>
             <h2>Update Paypal Configurations</h2>
             { clientId != null && secretId != null && 
             <Form
                name="normal_form"
                className="normal-form"
                initialValues={{ remember: true ,client_id: clientId, secret_id: secretId}}
                >
                <Form.Item
                    name="client_id"
                    label="Client Id"
                    rules={[
                    { 
                        required: true,
                        message: 'Please enter client title' 
                    }]
                    }
                >
                    <Input placeholder="Enter client Id" onChange={(e)=>setClientId(e.target.value)}/>
                </Form.Item> 
                <Form.Item
                    name="secret_id"
                    label="Secret Id"
                    rules={[
                    { 
                        required: true,
                        message: 'Please enter secret id' 
                    }]
                    }
                >
                    <Input placeholder="Enter secret Id" onChange={(e)=>setSecretId(e.target.value)}/>
                </Form.Item> 
                <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
                    Update Keys
                </Button>
            </Form>}
        </div>
    )
}

export default PaypalConfigs
