import React,{useState,useEffect} from 'react'

import Button from 'antd/lib/button'
import Table from 'antd/lib/table';
import message from 'antd/lib/message'
import Title from 'antd/lib/typography/Title'

import { axiosAuthenticated,currencyFormatter } from "../helper";
import { baseUrl } from "../Config";
import SettlePaymentModal from './SettlePaymentModal';

const UnsettledPayments = () => {
    const [payments,setPayments] = useState(null) //Array
    const [isVisible,setIsvisible] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [writerId,setWwriterId] = useState()
    const [orderId,setOrderId] = useState()
    const [budget,setBudget] = useState()

    const handleClick = (record) => {
        setIsvisible(true)
        setWwriterId(record.writer_id)
        setOrderId(record.id)
        setBudget(record.budget)
    }

    useEffect(() => {
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/orders/unsettled`,
            method:'get'
        }).then((res)=>{
            if(res.data.status = true){
                setPayments(res.data.data)
            }
        },(err)=>{
            // message.error('Error occured fetching payments')
           // message.error(err)
            console.log(err)
        })
    }, [])

    const handleCancel = () => {
        setIsvisible(false)
    }

    const handleOk = () => {
        const payload = {
            "amount":budget,
            "writer_id": writerId,
            "order_id":orderId
        }
        setIsLoading(true)
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/orders/settle`,
            method:'post',
            data:payload
        }).then((res)=>{
            setIsLoading(false)
            if(res.data.status == true){
                message.success("payment settled successifuly")
                window.location.reload()
            }else{
                message.error("failed to settle payment")
            }
            setIsvisible(false)

        },(err)=>{
            setIsLoading(false)
            message.error("failed to settle payment")
            setIsvisible(false)

        })
    }

    const columns = [
        {
          title: 'Writer Name',
          dataIndex: 'name',
          key: 'name',
          render: text => <p>{text}</p>,
        },
        {
          title: 'Unsettled Amount',
          dataIndex: 'budget',
          key: 'title',
          render: text => <p>{currencyFormatter.format(text)}</p>
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text,record) => <Button onClick={()=>{handleClick(record)}} type="primary">
                Settle Payment
            </Button>,
        }
    ];

    return (
        <div className="site-card-wrapper block container-fluid" style={{marginTop:'50px',alignItems:'center',justifyContent:'center',margin:15}}>
            <Title level={3}>Unsettled Payments</Title>       
            <Table columns={columns} dataSource={payments}/>
            <SettlePaymentModal isLoading={isLoading} isVisible={isVisible}
            handleCancel={handleCancel} handleOk={handleOk}/>
        </div>
    )
}

export default UnsettledPayments
