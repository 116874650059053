import React,{useState,useEffect} from 'react'
import Table from 'antd/lib/table';
import Space from 'antd/lib/space';

import { axiosAuthenticated,currencyFormatter } from "../helper";
import { baseUrl } from "../Config";
import { Button } from 'antd';

const OrderHistory = () => {

    const [orders,setOrders] = useState(null) //Array

    useEffect(() => {
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/order/history`,
            method:'get'
        }).then((res)=>{
            if(res.data.status = true){
                setOrders(res.data.data)
            }
        },(err)=>{
            // message.error('Error occured fetching payments')
           // message.error(err)
            console.log(err)
        })
    }, [])

    const columns = [
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          render: text => <>{text}</>,
        },
        {
          title: 'Budget',
          dataIndex: 'budget',
          key: 'budget',
          render: text => <>{currencyFormatter.format(text)}</>
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            key: 'deadline',
            render: text => <> {text} </>,
        },
        {
            title: 'Status',
            dataIndex: 'settled',
            key: 'settled',
            render: (text, record) => (
                <Space size="middle">
                  {(() => {
                    switch (record.assigned) {
                      case 0:return <a>unassigned</a>
                      case 1: return <a>assigned</a>
                      case 2: return <a>in review</a>
                      case 5: return (<a>finished/accepted</a>)
                      case 4: return (<a>finished</a>)
                      default: return <a>unassigned</a>
                    }
                  })()}
                </Space>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => <>{text}</>,
        },
        {
            title: 'Action',
            key: 'l',
            render: (text,record) => <a href={`/order/view/${record.id}`}><Button>View Order</Button></a>,
        },
    ];

    return (
        <div className="site-card-wrapper block container-fluid" style={{marginTop:'50px',alignItems:'center',justifyContent:'center',marginLeft:5,marginRight:5}}>
            <Table columns={columns} dataSource={orders}/>
        </div>
    )
}

export default OrderHistory
