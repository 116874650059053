import React,{useState} from 'react'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import Upload from 'antd/lib/upload';
import message from 'antd/lib/message'

import {axiosAuthenticated} from '../helper'
import {baseUrl} from '../Config'

import UploadOutlined from '@ant-design/icons/UploadOutlined';

const SetSiteLabels = () => {
    const [fileList,setFileList] = useState([]);
    const [title,setTitle] = useState();
    const [isLoading,setIsLoading] = useState(false)
    const [isLoadingFile,setIsLoadingFile] = useState(false)

    const props = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList)
            console.log(newFileList)
        },
        beforeUpload: file => {
                setFileList([...fileList, file])
                return false;
        },
        fileList,
    };

    const handleSubmit = () => {
        setIsLoading(true)
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/update/sitename`,
            method:'post',
            data: {
                'name': title
            }
        }).then((res)=>{
            setIsLoading(false)

            if(res.data.status == true){
                message.success("Title updated successifully")
            }else{
                message.error("Failed to update site name")
            }
        },(err)=>{
            setIsLoading(false)
            message.error("Failed to update site name")
        });
    }

    const handleSubmitFile = () => {
        setIsLoadingFile(true)
        const formData = new FormData();
        formData.append('image', fileList[0]);
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/update/sitelogo`,
            method:'post',
            data: formData
        }).then((res)=>{
            setIsLoadingFile(false)
            if(res.data.status == true){
                message.success("logo updated successifully")
            }else{
                message.error("Failed to update logo")
            }
        },(err)=>{
            setIsLoadingFile(false)
            message.error("Failed to update logo")
        });
    }


    return (
        <div className="site-card-wrapper block container-fluid" style={{marginTop:'50px',alignItems:'center',justifyContent:'center',width:'100vh'}}>
             <h2>Update Site Name</h2>
             <Form
                name="normal_form"
                className="normal-form"
                initialValues={{ remember: true }}
                >
                <Form.Item
                    name="title"
                    rules={[
                    { 
                        required: true,
                        message: 'Please enter article title' 
                    }]
                    }
                >
                    <Input placeholder="Article title" onChange={(e)=>setTitle(e.target.value)}/>
                </Form.Item> 
                <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
                    Publish
                </Button>
            </Form>
            <br></br><br></br>
            <h2>Update Site Name</h2>
             <Form
                name="normal_form"
                className="normal-form"
                initialValues={{ remember: true }}>
                <Upload {...props} accept=".jpg,.png">
                    <Button type='primary' icon={<UploadOutlined />}>Select logo image</Button>
                </Upload><br></br>
                <Button key="submit" type="primary" loading={isLoadingFile} onClick={handleSubmitFile}>
                    Update Logo
                </Button>
            </Form>
        </div>
    )
}

export default SetSiteLabels
