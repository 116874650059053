import React,{useState,useEffect} from 'react'

import Table from 'antd/lib/table';
import Title from 'antd/lib/typography/Title'

import { axiosAuthenticated,currencyFormatter } from "../helper";
import { baseUrl } from "../Config";

const SettledPayments = () => {

    const [payments,setPayments] = useState(null) //Array


    useEffect(() => {
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/orders/settled`,
            method:'get'
        }).then((res)=>{
            if(res.data.status = true){
                setPayments(res.data.data)
            }
        },(err)=>{
            // message.error('Error occured fetching payments')
           // message.error(err)
            console.log(err)
        })
    }, [])

    const columns = [
        {
            title: '#id',
            dataIndex: 'id',
            key: 'id',
            render: text => <p>{text}</p>,
        },
        {
          title: 'Writer Name',
          dataIndex: 'name',
          key: 'name',
          render: text => <p>{text}</p>,
        },
        {
          title: 'Amount',
          dataIndex: 'budget',
          key: 'title',
          render: text => <p>{currencyFormatter.format(text)}</p>
        },
        {
            title: 'Date settled',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: text => <p>{text}</p>,
          },
    ];

    return (
        <div className="site-card-wrapper block container-fluid" style={{marginTop:'50px',alignItems:'center',justifyContent:'center',margin:15}}>
            <Title level={3}>Settled Payments</Title>
            <Table columns={columns} dataSource={payments}/>
        </div>
    )
}

export default SettledPayments
