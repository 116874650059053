import React,{useState} from 'react';
import { Switch,Route,BrowserRouter as Router} from "react-router-dom";
import './App.css';
import 'antd/dist/antd.css';
import Title from 'antd/lib/typography/Title';

import Row from 'antd/lib/row';
import Layout from 'antd/lib/layout';
import Col from 'antd/lib/col'

import AppHeader from './common/header'
import AppFooter from './common/footer'

import {UniversalProvider} from './context/UniversalContext';
import {ModalProvider} from './context/ModalContext';

import Sider from './Sider';
import {Admin,UpdateLanding,OrderHistory,OrderItem,AdminLogin} from '../components/admin'
import { ListPricing,AddPrice } from "../components/pricing";
import EditPricePlan from './pricing/EditPricePlan';
import DeletePricePlan from './pricing/DeletePricePlan';
import ListWriters from './writers/ListWriters';
import ListBusinesses from './writers/ListBusinesses';
import ListAgencies from './writers/ListAgencies';
import AgencyOrders from './writers/AgencyOrders';
import BusinessOrders from "./writers/BusinessOrders";
import WriterOrders from "./writers/WriterOrders";
import {ListTestimonials,EditReview,DeleteReview,AddTestimonial,ListApprovedTestimonials} from "./testimonial";
import { SettledPayments, UnsettledPayments } from "./payments";
import {SetSiteLabels,SetEmailConfigs} from './setting'
import { PaypalConfigs } from "./paypal";

import { Statistics } from "./report";
import Logout from './Logout';
import ListUnApprovedTestimonials from './testimonial/ListUnApprovedTestimonial';
import AdminUsers from './admin/AdminUsers';
import AddAdminUser from './admin/AddAdminUser';

const { Header, Content, Footer } = Layout;

const App = () => {
    const user =  JSON.parse(localStorage.getItem('user'))

    const [render, updateRender] = useState(1);
    
    const handleMenuClick = menu => {
    updateRender(menu.key);
    };

    return (
        <UniversalProvider>
        <Layout className="mainLayout">
            <Header>
                <AppHeader/>
            </Header> 
            <Layout>
                <Content>
                <Router>
                    {user == null && <AdminLogin/>}
                    {user != null &&
                    <Row>
                        {
                        user != null && <Col span={4}><Sider handleClick={handleMenuClick}/></Col>
                        }
                        <Col span={20}>
                           
                           <div style={{maxHeight:750,overflowY:'scroll'}}>

                                <Switch>
                                    <Route exact path={`/`}>
                                        {/* <ListBlogPosts/> */}
                                        {/* {components[render]} */}
                                        {/* <AdminLogin/> */}
                                        <Admin/>
                                    </Route>
                                    <Route exact path="/admin">
                                        <Admin/>
                                    </Route>
                                    <Route exact path="/orders/history">
                                        <OrderHistory/>
                                    </Route>
                                    <Route path="/order/view/:id">
                                        <ModalProvider>
                                            <OrderItem  />
                                        </ModalProvider>
                                    </Route>
                                    {/* <Route exact path={`/blog`}>
                                        <CreateBlogPost/>
                                    </Route> */}
                                    <Route exact path={`/landing`}>
                                        <UpdateLanding/>
                                    </Route>
                                    {/* <Route exact path={`/how-it-works`}>
                                        <HowItWorks/>
                                    </Route> */}
                                    {/* <Route exact path={`/blog/list`}>
                                        <ListBlogPosts/>
                                    </Route>
                          
                                    <Route exact path={`/blog/edit/:id`}>
                                        <EditBlogPost/>
                                    </Route>
                                    <Route exact path={`/blog/delete/:id`}>
                                        <DeletePost/>
                                    </Route> */}
                                    <Route exact path={`/login`}>
                                        <AdminLogin/>
                                    </Route>
                                    
                                    <Route exact path={`/user/add`}>
                                        {user.email == "admin@admin.com" &&  <AddAdminUser/>}
                                    </Route>
                                    <Route exact path={`/users`}>
                                        {user.email == "admin@admin.com" && <AdminUsers/> }
                                    </Route>
                             
                                    <Route exact path={`/logout`}>
                                        <Logout/>
                                    </Route>
                                    <Route exact path={`/pricing`}>
                                        <ListPricing/>
                                    </Route>
                                    <Route exact path={`/pricing/add`}>
                                        <AddPrice/>
                                    </Route>
                                    <Route exact path={`/pricing/edit/:id`}>
                                        <EditPricePlan/>
                                    </Route>
                                    <Route exact path={`/pricing/delete/:id`}>
                                        <DeletePricePlan/>
                                    </Route>
                                    <Route exact path={`/writers`}>
                                        <ListWriters/>
                                    </Route>
                                    <Route exact path={`/writer/orders/:id`}>
                                        <WriterOrders/>
                                    </Route>
                                    <Route exact path={`/agencies`}>
                                        <ListAgencies/>
                                    </Route>
                                    <Route exact path={`/agency/orders/:id`}>
                                        <AgencyOrders/>
                                    </Route>
                                    <Route exact path={`/businesses`}>
                                        <ListBusinesses/>
                                    </Route>
                                    <Route exact path={`/business/orders/:id`}>
                                        <BusinessOrders/>
                                    </Route>
                                    <Route exact path={`/testimonial`}>
                                        <ListTestimonials/>
                                    </Route>
                                    <Route exact path={`/testimonial/unapproved`}>
                                        <ListUnApprovedTestimonials/>
                                    </Route>
                                    <Route exact path={`/testimonial/add`}>
                                        <AddTestimonial/>
                                    </Route>
                                    <Route exact path={`/testimonial/edit/:id`}>
                                        <EditReview/>
                                    </Route>
                                    <Route exact path={`/testimonial/delete/:id`}>
                                        <DeleteReview/>
                                    </Route>
                                    <Route exact path={`/payments`}>
                                        <UnsettledPayments/>
                                    </Route>
                                    <Route exact path={`/payments/settled`}>
                                        <SettledPayments/>
                                    </Route>
                                    <Route exact path={`/settings/sitename`}>
                                        <SetSiteLabels/>
                                    </Route>
                                    <Route exact path={`/settings/email`}>
                                        <SetEmailConfigs/>
                                    </Route>
                                    <Route exact path={`/paypal`}>
                                        <PaypalConfigs/>
                                    </Route>
                                    <Route exact path={`/statistics`}>
                                        <Statistics/>
                                    </Route>
                               </Switch>
                            </div>              
                        </Col>
                </Row>}
                </Router>

                </Content>
            </Layout>
            <Footer>
                <AppFooter/>  
            </Footer>      
        </Layout>
    </UniversalProvider>)
};

export default App;
//render(<App />, document.getElementById('app'))
