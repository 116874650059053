import { useState, useEffect } from "react";

import { axiosAuthenticated } from "./helper";
import {baseUrl} from './Config'

const contentApi = axiosAuthenticated().create({
    baseURL: baseUrl,
 });

export const useAdminFetch = ({ method, url, data = null, config = null }) => {
   const [response, setResponse] = useState(null);
   const [error, setError] = useState("");
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const fetchData = async () => {
         try {
            contentApi({
                method,
                url,
                data
            }).then((res)=>{
                setResponse(res.data.data)
                setIsLoading(false)
            },(err)=>{
                setIsLoading(false)
            });
         } catch (err) {
            setError(err);
         }
      };

      fetchData();
   }, [ method, url, data, config]);

   return { response, error, isLoading };
}

 
//  export const programApi = axios.create({
//     baseURL: baseUrl,
//  });
