import React,{useState,useEffect} from 'react'
import axios from 'axios'

import Button from 'antd/lib/button'
import Table from 'antd/lib/table';
import message from 'antd/lib/message'
import Title from 'antd/lib/typography/Title'


import ApproveOrderModal from './OrderApproveModal';
import {baseUrl} from '../Config'

const Admin = () => {
    const [submissions,setSubmissions] = useState();
    const [isVisible,setIsvisible] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [assignmentId,setAssignmentId] = useState()
    const [orderId,setOrderId] = useState()
    const access_token = localStorage.getItem('access_token')
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`

    const handleCancel = () => {
        setIsvisible(false)
    }

    const handleOk = () => {
        const payload = {
            "assignment_id":assignmentId,
            "order_id":orderId
        }
        setIsLoading(true)
        axios({
            url:`${baseUrl}/api/v1/admin/order/approve`,
            method:'post',
            data: payload
        }).then((response)=>{
            console.log(response)
            setIsvisible(false)
            setIsLoading(false)
            message.success("order approved successifully")
            window.location = '/admin'
        },(error)=>{
            console.log(error)
            setIsvisible(false)
            setIsLoading(false)
            message.error('failed to approve order. Try again')
        })
    }

    useEffect(()=>{
        axios({
            url:`${baseUrl}/api/v1/admin/order/active/submissions`,
            method:'get',
        })
        .then((response)=>{
            setSubmissions(response.data.data)
        },(err) => {
            console.log(err)
        });
    },[])
    
    const columns = [
        {
          title: 'Order Id',
          dataIndex: 'id',
          key: 'id',
          render: text => <a>{text}</a>,
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Submitted At',
          dataIndex: 'submitted_at',
          key: 'submitted_at',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text,record) => <Button>
                <a target='_blank' href={`${baseUrl}/storage/orders/${record.file_name}`}>Preview submission</a>
            </Button>,
        },
        {
            title: 'Approve',
            dataIndex: '',
            key: 'x',
            render: (text,record) => <Button onClick={()=>{
                setIsvisible(true);setOrderId(record.id) ;setAssignmentId(record.assignment_id)}
                }>
                <a>Approve submission</a>
            </Button>,
        },
        {
            title: 'Comments',
            dataIndex: '',
            key: 'x',
            render: (text,record) => <>
                <small>{record.email}</small>
                <Button><a href={`mailto:${record.email}`} >Chat Writer</a></Button>
            </>,
        }
    ];

    return (
        <div className="site-card-wrapper block container-fluid" style={{display:'flex',flexDirection:'column',marginTop:'50px',alignItems:'center',justifyContent:'center',marginLeft:5,marginRight:5}}>
            <Title level={2}>Order Waiting Review</Title>
            <Table columns={columns} dataSource={submissions}/>
            <ApproveOrderModal isLoading={isLoading} isVisible={isVisible}
            handleCancel={handleCancel} handleOk={handleOk}/>
        </div>
    )
}

export default Admin
