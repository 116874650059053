import React,{useState,useEffect}from 'react'

import Table from 'antd/lib/table'
import message from 'antd/lib/message'
import Space from 'antd/lib/space'
import Button from "antd/lib/button";
import Moment from 'react-moment';

import {baseUrl} from '../Config'
import { axiosAuthenticated } from "../helper";

import WriterApproveModal from './WriterApproveModal';
import WriterActivateModal from './WriterActivateModal';
import WriterDeactivateModal from './WriterDeactivateModal';

const ListWriters = () => {
    const [writers,setWriters] = useState()
    const [writerId,setWriterId] = useState()

    const [apprLoading,setApprLoading] = useState(false)
    const [activateLoading,setActivateLoading] = useState(false)
    const [deactivateLoading,setDeactivateLoading] = useState(false)

    const [apprVisible,setApprVisible] = useState(false)
    const [activateVisible,setActivateVisible]= useState(false)
    const [deacitivateVisible,setDeactivateVisible] = useState(false)


    const handleCancel = () => {
      setActivateVisible(false)
      setApprVisible(false)
      setDeactivateVisible(false)
    }

    const handleApprove = () => {
      setApprVisible(true)
      setApprLoading(true)
      axiosAuthenticated()({
        url:`${baseUrl}/api/v1/admin/writer/approve/${writerId}`,
        method:'get'
      }).then((res)=>{
        setApprVisible(false)
        setApprLoading(false)
        if(res.data.status == true){
          message.success('Writer approved')
        }else{
          message.error('Failed to approve writer')
        }
      },(err)=>{
        message.error('Failed to approve writer')
        setApprVisible(false)
        setApprLoading(false)
      })
    }
    
    const handleActivate = () => {
      setActivateVisible(true)
      setActivateLoading(true)
      axiosAuthenticated()({
        url:`${baseUrl}/api/v1/admin/writer/activate/${writerId}`,
        method:'get'
      }).then((res)=>{
        setActivateVisible(false)
        setActivateLoading(false)
        if(res.data.status == true){
          message.success('Writer activated')
        }else{
          message.error('Failed to activate writer')
        }
      },(err)=>{
        message.error('Failed to activate writer')
        setActivateVisible(false)
        setActivateLoading(false)
      })
    }

    const handleDeactivate = () => {
      setDeactivateVisible(true)
      setDeactivateLoading(true)
      axiosAuthenticated()({
        url:`${baseUrl}/api/v1/admin/writer/deactivate/${writerId}`,
        method:'get'
      }).then((res)=>{
        setDeactivateVisible(false)
        setDeactivateLoading(false)
        if(res.data.status == true){
          message.success('Writer deactivated')
        }else{
          message.error('Failed to deactivate writer')
        }
      },(err)=>{
        message.error('Failed to deactivate writer')
        setDeactivateVisible(false)
        setDeactivateLoading(false)
      })
    }



    useEffect(()=>{
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/writers/list`,
            method:'get'
        }).then((res)=>{
            console.log(res)
            if(res.data.status == true){
                setWriters(res.data.data)
            }else{
                message.error("failed to load writers")
            }
        },(err)=>{
            message.error("failed to load writers")
            console.log(err)
        })
    },[])

    const columns = [
        {
          title: 'Name',
          key: 'name',
          render: (text, record) => (
            <Space size="middle">
                <a href={`/writer/orders/${record.id}`}>{record.name}</a>
            </Space>
          ),
        },
        {
            title: 'Phone',
            key: 'phone',
            render: (text, record) => (
              <Space size="middle">
               {record.phone}
              </Space>
            ),
        },
        {
            title: 'Email',
            key: 'email',
            render: (text, record) => (
              <Space size="middle">
                {record.email}
              </Space>
            ),
        },
        {
          title: 'Actions',
          key: 'tag',
          render: (text, record) => (
            <Space size="middle">
              {record.is_approved == 1 ?
                    <Button disabled type="primary">approve</Button> :<Button disabled onClick={()=>{setApprVisible(true);setWriterId(record.id)}} type="primary">approve</Button>
              }
              {record.is_active == 1 ?
              <Button onClick={()=>{setDeactivateVisible(true);setWriterId(record.id)}} danger>deactivate</Button>
              :<Button onClick={()=>{setActivateVisible(true);setWriterId(record.id)}}>activate</Button>
        }
            </Space>
          ),
      },
        {
          title: 'Registered',
          key: 'deadline',
          render: (text, record) => (
            <Space size="middle">
              <Moment format="YYYY/MM/DD">
                {record.created_at}
              </Moment>
            </Space>
          ),
        },
      ];
    return (
        <div className="container-fluid" style={{justifyContent:'center',marginTop:80}}>
            <h2 style={{margin:20}}>Writers list</h2>
            <Table columns={columns} dataSource={writers} />
            <WriterApproveModal isLoading={apprLoading} isVisible={apprVisible} handleCancel={handleCancel} handleOk={handleApprove}/>
            <WriterActivateModal isLoading={activateLoading} isVisible={activateVisible} handleCancel={handleCancel} handleOk={handleActivate}/>
            <WriterDeactivateModal isLoading={deactivateLoading} isVisible={deacitivateVisible} handleCancel={handleCancel} handleOk={handleDeactivate}/>

        </div>
    )
}

export default ListWriters
