import React,{useState,useEffect} from 'react'
import Button from 'antd/lib/button'
import TextArea from 'antd/lib/input/TextArea';
import Input from 'antd/lib/input';

import Form from 'antd/lib/form';
import Upload from 'antd/lib/upload';
import message from 'antd/lib/message';

import UploadOutlined from '@ant-design/icons/UploadOutlined';
import axios from 'axios';
import {baseUrl} from '../Config';

const UpdateLanding = () => {
    const [title1,setTitle1] = useState()
    const [title2,setTitle2] = useState()
    const [title3,setTitle3] = useState()
    const [title4,setTitle4] = useState()

    const [id1,setId1] = useState()
    const [id2,setId2] = useState()
    const [id3,setId3] = useState()
    const [id4,setId4] = useState()

    const [content1,setContent1] = useState()
    const [content2,setContent2] = useState()
    const [content3,setContent3] = useState()
    const [content4,setContent4] = useState()

    const [fileList1,setFileList1] = useState([])
    const [fileList2,setFileList2] = useState([])
    const [fileList3,setFileList3] = useState([])
    
    const [isLoading,setIsLoading] = useState()

    const [blocks,setBlocks] = useState(null)
    const [blocks1,setBlocks1] = useState()
    const [blocks2,setBlocks2] = useState()
    const [blocks3,setBlocks3] = useState()

    
    const access_token = localStorage.getItem('access_token')
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`

    useEffect(()=>{
        axios({
            method:'get',
            url:`${baseUrl}/api/v1/admin/landing/blocks`
        }).then((res)=>{
            const data = res.data.data
            setBlocks(data)
            setTitle1(data[0].title)
            setTitle2(data[1].title)
            setTitle3(data[2].title)
            setTitle4(data[3].title)

            setContent1(data[0].content)
            setContent2(data[1].content)
            setContent3(data[2].content)
            setContent4(data[3].content)

            setId1(data[0].id)
            setId2(data[1].id)
            setId3(data[2].id)
            setId4(data[3].id)
        },(err)=>{
    
        })
    },[])

    const handleTitle = (e) => {
    }

    const handleSubmit = (e) => {

    }

    const handleSubmitBlock1 = () => {
        const formData = new FormData();

        if(fileList1.length>0){
          formData.append('image', fileList1[0]);
        }
        formData.append('title', title1);
        formData.append('content', content1);

        axios({
            method:'post',
            url:`${baseUrl}/api/v1/admin/landing/block/${id1}`,
            data:formData
        }).then((res)=>{
            const data = res.data.data
            if(res.data.status = true){
                message.success("Block updated")
            }else{
                message.error("Failed to update block")
            }
        },(err)=>{
            message.error("Failed to update block")
        })
    }

    const handleSubmitBlock2 = () => {
        const formData = new FormData();

        if(fileList2.length>0){
          formData.append('image', fileList2[0]);
        }
        formData.append('title', title2);
        formData.append('content', content2);

        axios({
            method:'post',
            url:`${baseUrl}/api/v1/admin/landing/block/${id2}`,
            data:formData
        }).then((res)=>{
            const data = res.data.data
            if(res.data.status = true){
                message.success("Block updated")
            }else{
                message.error("Failed to update block")
            }
        },(err)=>{
            message.error("Failed to update block")
        })
    }

    const handleSubmitBlock3 = () => {
        const formData = new FormData();

        if(fileList3.length>0){
          formData.append('image', fileList3[0]);
        }
        formData.append('title', title3);
        formData.append('content', content3);

        axios({
            method:'post',
            url:`${baseUrl}/api/v1/admin/landing/block/${id3}`,
            data:formData
        }).then((res)=>{
            const data = res.data.data
            if(res.data.status = true){
                message.success("Block updated")
            }else{
                message.error("Failed to update block")
            }
        },(err)=>{
            message.error("Failed to update block")
        })
    }

    const handleSubmitBlock4 = () => {
        const formData = new FormData();

    
        formData.append('title', title4);
        formData.append('content', content4);

        axios({
            method:'post',
            url:`${baseUrl}/api/v1/admin/landing/block/${id4}`,
            data:formData
        }).then((res)=>{
            const data = res.data.data
            if(res.data.status = true){
                message.success("Block updated")
            }else{
                message.error("Failed to update block")
            }
        },(err)=>{
            message.error("Failed to update block")
        })
    }

    const props1 = {
        onRemove: file => {
          const index = fileList1.indexOf(file);
          const newFileList = fileList1.slice();
          newFileList.splice(index, 1);
          setFileList1(newFileList)
          ()
          console.log(newFileList)
        },
        beforeUpload: file => {
              setFileList1([...fileList1, file])
              return false;
        },
        fileList1,
      };
      const props2 = {
        onRemove: file => {
          const index = fileList2.indexOf(file);
          const newFileList = fileList2.slice();
          newFileList.splice(index, 1);
          setFileList2(newFileList)
          ()
          console.log(newFileList)
        },
        beforeUpload: file => {
              setFileList2([...fileList2, file])
              return false;
        },
        fileList2,
      };
      const props3 = {
        onRemove: file => {
          const index = fileList3.indexOf(file);
          const newFileList = fileList3.slice();
          newFileList.splice(index, 1);
          setFileList3(newFileList)
          ()
          console.log(newFileList)
        },
        beforeUpload: file => {
              setFileList3([...fileList3, file])
              return false;
        },
        fileList3,
      };
    return (
        <>
            <div className="site-card-wrapper block container-fluid" style={{marginTop:'50px',alignItems:'center',justifyContent:'center',width:'100vh'}}>
                <h2>Update landing page Content</h2>
                {blocks != null &&
                <>
                <Form
                    name="form1"
                    className="normal-form"
                    initialValues={{ remember: true,name1:blocks[0].title,content1: blocks[0].content}}
                    >
                    <Form.Item
                        name="name1"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter header' 
                        }]
                        }
                    >
                        <Input placeholder="Article title" onChange={(e)=>setTitle1(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        name="content1"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter content' 
                        }]
                        }>
                        <TextArea rows={4} onChange={(e) => setContent1(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        name="file1"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter content' 
                        }]
                        }>
                        <Upload {...props1} accept=".jpg,.png">
                            <Button type='primary' icon={<UploadOutlined />}>Select a feature image</Button>
                        </Upload><br></br>
                    </Form.Item>
                    <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmitBlock1}>
                        update block
                    </Button>
                </Form>
                <br></br>
                <Form
                    name="form2"
                    className="normal-form"
                    initialValues={{ remember: true,name2:blocks[1].title,content2: blocks[1].content}}
                    >
                    <Form.Item
                        name="name2"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter header' 
                        }]
                        }
                    >
                        <Input placeholder="title" onChange={(e)=>setTitle2(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        name="content2"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter content' 
                        }]
                        }>
                        <TextArea rows={4} onChange={(e) => setContent2(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name="file2"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter content' 
                        }]
                        }>
                        <Upload {...props2} accept=".jpg,.png">
                            <Button type='primary' icon={<UploadOutlined />}>Select a feature image</Button>
                        </Upload><br></br>
                    </Form.Item>
                    <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmitBlock2}>
                        update block
                    </Button>
                </Form>
                <br></br>
                <Form
                    name="form2"
                    className="normal-form"
                    initialValues={{ remember: true,name3:blocks[2].title,content3: blocks[2].content}}
                    >
                    <Form.Item
                        name="name3"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter header' 
                        }]
                        }
                    >
                        <Input placeholder="title" onChange={(e)=>setTitle3(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        name="content3"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter content' 
                        }]
                        }>
                        <TextArea rows={4} onChange={(e) => setContent3(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        name="file3"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter content' 
                        }]
                        }>
                        <Upload {...props3} accept=".jpg,.png">
                            <Button type='primary' icon={<UploadOutlined />}>Select a feature image</Button>
                        </Upload><br></br>
                    </Form.Item>
                    <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmitBlock3}>
                        update block
                    </Button>
                </Form>
                <br></br>
                <Form
                    name="form2"
                    className="normal-form"
                    initialValues={{ remember: true,name4:blocks[3].title,content4: blocks[3].content}}
                    >
                    <Form.Item
                        name="name4"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter header' 
                        }]
                        }
                    >
                        <Input placeholder="title" onChange={(e)=>setTitle4(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        name="content4"
                        rules={[
                        { 
                            required: true,
                            message: 'Please enter content' 
                        }]
                        }>
                        <TextArea rows={4} onChange={(e)=>setContent4(e.target.value)} />
                    </Form.Item>
    
                    <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmitBlock4}>
                        update block
                    </Button>
                </Form>
                </>
}
            </div>
        </>
    )
}

export default UpdateLanding
