import React,{createContext,useState} from 'react'

export const UniversalContext = createContext()

//include state in the context
export const UniversalProvider = (props) => {

    const [user,setUser] = useState({})
    const [accessToken,setAccessToken] = useState()
    const [orders,setOrders] = useState([])
    const [plans,setPlans] = useState([])
    const [credit,setCredit] = useState()
    const [cart, setCart] = useState({})

    return (
       <UniversalContext.Provider value={{
           user_:[user,setUser],
           token_: [accessToken,setAccessToken],
           orders_:[orders,setOrders],
           plans_:[plans,setPlans],
           credit_:[credit,setCredit],
           cart_:[cart, setCart]
           }}>
               
           {props.children}

       </UniversalContext.Provider>
    )
}