import React,{useState,useEffect,useContext} from 'react'
import { useHistory } from "react-router-dom";
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select'
import message from 'antd/lib/message'
import Title from 'antd/lib/typography/Title'

import { axiosAuthenticated } from "../../components/helper";
import { baseUrl } from "../../components/Config";

import { ModalContext } from "../context/ModalContext";

const { Option } = Select;


function OrderAssignmentModal({orderId}) {
    const history = useHistory()
    const [writers,setWriters] = useState(null)
    const [writerId,setWriterId] = useState()
    const [isLoading,setIsLoading] = useState(false)

    const {assignModalVisibility} = useContext(ModalContext)
    const [isVisible,setVisible] = assignModalVisibility

    const handleCancel = () => {
        setVisible(false)
    }

    const handleOk = () => {
        const data = {
            "order_id":orderId,
            "writer_id": writerId
        }
        setIsLoading(true)
        const assignOrder = async () => {
            await axiosAuthenticated()({
                method:'post',
                url:`${baseUrl}/api/v1/admin/order/assign`,
                data:data,
            }).then(
                (response)=>{
                    console.log(response)
                
                    if(response.data.status == true){
                        setIsLoading(false)
                        setVisible(false)
                        message.success("Order asigned successifully")

                        history.push('/orders/history')

                    }else{
                        message.error('order assignment failed')
                    }
                }
                ,(error)=>{
                    console.log(error)
                    setIsLoading(false)
                    setVisible(false)
                    message.error('order assignment failed')
                    //history.push('/')
                }
            )
        }
        assignOrder()
    }

    useEffect(()=>{
        axiosAuthenticated()({
            url:`${baseUrl}/api/v1/admin/writers/list`,
            method:'get'
        }).then((res)=>{
            if(res.data.status == true){
                setWriters(res.data.data)
            }else{
                message.error("failed to load writers")
            }
        },(err)=>{
            message.error("failed to load writers")
        })
    },[])


    return (
        <div>
             <Modal
                visible={isVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button type='primary' key="back" onClick={handleCancel}>
                        Go back.
                    </Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                        Assign Order
                    </Button>,
                ]}>
                <div>
                    <Title level={4}>Assign order to writer </Title>
                    <br></br><br></br>
                    <Form>
                        <Form.Item
                            name="writer"
                            label="Select writer to assign order"
                            hasFeedback
                            >
                                {
                                    writers != null && 
                                    <Select placeholder="Please select writer to asign order" onChange={(value) => setWriterId(value) }>
                                    {
                                        writers.map(item=>(<Option key={item.id} value={item.id}>{`${item.name}`}</Option>))
                                    }
                                </Select>
                                }
                          
                        </Form.Item>
                        {/* <Form.Item>
                            <Button onClick={handleAssign} loading={loading} block type="primary" htmlType="submit">
                                Assign order
                            </Button>
                        </Form.Item> */}
                    </Form>
                </div>
            </Modal>   
        </div>
    )
}

export default OrderAssignmentModal
