import React,{useState} from 'react'
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { axiosAuthenticated } from "../helper";
import { baseUrl } from "../Config";
import Button from 'antd/lib/button';
import Upload from 'antd/lib/upload';
import message from 'antd/lib/message';

const OrderSubmitView = ({orderId}) => {

    const [fileList,setFileList] = useState([]);
    const [uploading,setIsUploading] = useState(false)

  const handleUpload = () => {

    const formData = new FormData();
    // fileList.forEach(file => {
    //   formData.append('files[]', file);
    // });
    formData.append('order', fileList[0]);
    formData.append('order_id',orderId);
    // formData.append('writer_id',writerId);
    setIsUploading(true)

  
    axiosAuthenticated()({
        url:`${baseUrl}/api/v1/admin/order/submit`,
        method:'post',
        data:formData,
    }).then(
        (response)=>{
            console.log(response)
            setFileList([])
            setIsUploading(false)
            message.success("Order submitted successifully");
            window.location.reload()
        },
        (error) => {
            setIsUploading(false)
            message.error("Failed to submit order");
        })
    };


    const props = {
      onRemove: file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList)
        console.log(newFileList)
      },
      beforeUpload: file => {
            setFileList([...fileList, file])
            return false;
      },
      fileList,
    };

    return (
      <>
        <Upload {...props} accept=".pdf,.docx">
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
        <br></br>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Start Upload'}
        </Button>
      </>
    );
  }

  export default OrderSubmitView