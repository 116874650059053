import React from 'react'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal';
import Title from 'antd/lib/typography/Title';


function ApproveOrderModal({isLoading,isVisible,handleCancel,handleOk}) {
   
    return (
        <div>
             <Modal
                visible={isVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button type='primary' key="back" onClick={handleCancel}>
                        No. Go back.
                    </Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                        Aprove Order
                    </Button>,
                ]}>
                <div>
                    <Title level={4}>Are you sure to approve this submission? </Title>
                </div>
            </Modal>   
        </div>
    )
}

export default ApproveOrderModal
