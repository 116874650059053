import React, { useState } from 'react'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';
import Card from 'antd/lib/card';
import Col from 'antd/lib/col'
import message from 'antd/lib/message'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { baseUrl } from "../Config";

const AddTestimonial = () => {
    const history = useHistory();
    const [name,setName] = useState()
    const [review,setReview] = useState()
    const [loading,setIsLoading] = useState(false);
    const access_token = localStorage.getItem('access_token')
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
    const handleSubmit = () => {
        setIsLoading(true)
        const payload = {
            "name":name,
            "review":review
        }
        axios({
            url:`${baseUrl}/api/v1/admin/testimonial`,
            method:'post',
            data:payload
        }).then((res)=>{
            if(res.data.status == true){
                message.success("Testimonial created successifully")
                history.push('/testimonial')
            }else{
                message.error("failed to create testimonial")
            }
            setIsLoading(false)

        },(err)=>{
            setIsLoading(false)
            message.error("failed to create testimonial")
        })
    }
    return (
        <div className="site-card-wrapper block container-fluid" style={{textAlign:'center'}}>
        <div style={{margin:15,textAlign:"center"}}>
            <Row style={{justifyContent:'center'}}>
                <Col sm={{span:24}}  xs={{span:24}} md={{span:16}} lg={{span:8}} style={{margin:5}}>
                    <Card bordered={true}>
                    <div>
                        <div className="titleHolder">
                            <h2>Add Customer Review</h2>
                        </div>
                        <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}>
                            <Form.Item
                                name="title"
                                rules={[
                                {
                                    required: true,
                                    message: 'enter customer name',
                                },
                                ]}>
                                <Input placeholder="enter customer name" onChange={(e)=>{setName(e.target.value)}}/>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter customer review'
                                    }]}
                            >
                                <Input placeholder='Enter customer review' onChange={(e)=>{setReview(e.target.value)}} />
                            </Form.Item>
                
                            <Form.Item>
                                <Button loading={loading} className="cta-btn-login" block type="primary" onClick={handleSubmit}>Add Review</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    </Card>
                </Col>
            </Row>
        </div>
    </div>
    )
}

export default AddTestimonial
