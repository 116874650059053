import axios from 'axios';
import { baseUrl } from "./Config";

export const axiosAuthenticated = () => {
    const access_token = localStorage.getItem('access_token')
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
    axios.defaults.baseURL = baseUrl;

    return axios
}

export const user = () => {
    //get user from localstorage
    const user = JSON.parse(localStorage.getItem('user'))
    return user
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });




// export const dateFormatter = (date) => {
//     var formattedDate = format(date, "MMMM do, yyyy H:mma");
//     return formattedDate
// }
