import React,{useState} from 'react'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input';
import Title from 'antd/lib/typography/Title';

import Form from 'antd/lib/form';

import message from 'antd/lib/message'
import axios from 'axios'
import {baseUrl} from '../Config'

const AdminLogin = () => {
    // const layout = {
    //     labelCol: { span: 8 },
    //     wrapperCol: { span: 16 },
    // };

    const [email,setEmail] = useState();
    const [password,setPassword] = useState()
    const [loading,setIsLoading] = useState(false)

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const payload = {
        "email":email,
        "password":password
    }

    const handleLogin = async () => {
        setIsLoading(true)
        await axios({
            method:'post',
            url:`${baseUrl}/api/v1/admin/login`,
            data:payload
        }).then((response)=>{
            const res = response.data
            if(res.status == true){
                //localStorage.setItem('access_token', response.data.access_token);
                setIsLoading(false)
                
                localStorage.setItem('access_token', res.data.access_token);
                localStorage.setItem('user',JSON.stringify(res.data.user))
                localStorage.setItem('type','admin')

                //history.push('/admin')
                window.location = '/admin'
                message.success('login successful');

            }else{
                message.error('login failed')
                setIsLoading(false)
            }
        },(err)=>{
            message.error('login failed')
            setIsLoading(false)
        })
        setIsLoading(false)
    }

    return (
        <div className="site-card-wrapper block container-fluid" style={{display:'flex',flexDirection:'column',marginTop:'50px',alignItems:'center',justifyContent:'center',marginLeft:5,marginRight:5}}>
                <Title level={2}>Login</Title>
                <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                >
                    <Form.Item
                        name="email"
                        rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid email',
                        },
                        {
                            required: true,
                            message: 'Please input your email',
                        },
                        ]}
                    >
                        <Input placeholder="Email Address" onChange={handleEmail}/>
                    </Form.Item>
    
                    <Form.Item
                        name="password"
                    >
                        <Input.Password placeholder="password" onChange={handlePassword}/>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={handleLogin} loading={loading} block type="primary" htmlType="submit">
                        Login
                        </Button>
                    </Form.Item>
                </Form>
        </div>
    )
}

export default AdminLogin
