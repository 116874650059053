import React, { useState,useEffect } from 'react'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';
import Card from 'antd/lib/card';
import Col from 'antd/lib/col'
import message from 'antd/lib/message'
import axios from 'axios';
import { useHistory,useParams } from "react-router-dom";
import { baseUrl } from "../Config";

const EditPricePlan = () => {
    const {id} = useParams();

    const history = useHistory();
    const [title,setTitle] = useState(null)
    const [description,setDescription] = useState(null)
    const [price,setPrice] = useState(null);
    const [priceId,setPriceId] = useState()
    const [loading,setIsLoading] = useState(false);
    const access_token = localStorage.getItem('access_token')
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`

    useEffect(()=>{
        axios({
            url:`${baseUrl}/api/v1/admin/pricing/${id}`,
            method:'get',
        }).then((res)=>{
            if(res.data.status == true){
                const data = res.data.data
                // message.success("Pricing plan created successifully")
                //history.push('/pricing')
                setDescription(data.description)
                setTitle(data.title)
                setPrice(data.price)
                setPriceId(id)
            }else{
                message.error("failed to load the plan")
            }
        },(err)=>{
            message.error("failed to load the plan")
        })
    },[])

    const handleSubmit = () => {
        setIsLoading(true)
        const payload = {
            "title": title,
            "description": description,
            "price":price
        }

        axios({
            url:`${baseUrl}/api/v1/admin/pricing/${priceId}`,
            method:'put',
            data:payload
        }).then((res)=>{
            if(res.data.status == true){
                const data = res.data.data
                message.success("Pricing plan updated")
                history.push('/pricing')
            }else{
                message.error("failed to update pricing plan")
            }
            setIsLoading(false)
        },(err)=>{
            message.error("failed to update pricing plan")
            setIsLoading(false)
        })   
    }
    return (
        <div className="site-card-wrapper block container-fluid" style={{textAlign:'center'}}>
        <div style={{margin:15,textAlign:"center"}}>
            <Row style={{justifyContent:'center'}}>
                <Col sm={{span:24}}  xs={{span:24}} md={{span:16}} lg={{span:8}} style={{margin:5}}>
                    <Card bordered={true}>
                    <div>
                        <div className="titleHolder">
                            <h2>edit pricing pan</h2>
                        </div>
                        { (description != null && title != null  && price != null)  &&(
                        <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true,title:title,description:description,price:price }}>
                            <Form.Item
                                name="title"
                                rules={[
                                {
                                    required: true,
                                    message: 'enter pricing title',
                                },
                                ]}>
                                <Input value={title} placeholder="enter pricing title" onChange={(e)=>{setTitle(e.target.value)}}/>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter pricing description',
                                    }]}
                            >
                                <Input value={description} placeholder="Enter pricing description" onChange={(e)=>{setDescription(e.target.value)}} />
                            </Form.Item>
                            <Form.Item
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter price value',
                                    }]}
                            >
                                <Input value={price} placeholder="Enter pricing value" onChange={(e)=>{setPrice(e.target.value)}} />
                            </Form.Item>
                            <Form.Item>
                                <Button loading={loading} className="cta-btn-login" block type="primary" onClick={handleSubmit}>Edit Plan</Button>
                            </Form.Item>
                        </Form>
                        )}
                    </div>
                    </Card>
                </Col>
            </Row>
        </div>
    </div>
    )
}

export default EditPricePlan
