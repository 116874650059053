import React,{useEffect} from 'react'
import { useHistory } from "react-router-dom"
import {axiosAuthenticated} from './helper'

const Logout = () => {
    let history = useHistory();

    useEffect(()=>{
        axiosAuthenticated()({
          method: 'get',
          url: '/api/v1/business/logout',
        }).then(res => {
          localStorage.setItem('access_token',null)
          localStorage.setItem('user',null)
          localStorage.setItem('type',null)
          //history.push('/')
          window.location = '/'
        },(error) => {
           history.push('/')
      })
    },[])

    return (
        <div>
            
        </div>
    )
}

export default Logout
