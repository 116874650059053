import React,{useEffect} from 'react'
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import Title from "antd/lib/typography/Title";


import {useAdminFetch} from "../hooks";
import { currencyFormatter } from "../helper";
function Statistics() {
    const {response, error, isLoading} = useAdminFetch({method:"get",
    url:'/api/v1/admin/stats'})
    useEffect(() => {
        console.log(response)
    }, [response])

    return (
        <div className="site-card-wrapper block container-fluid" style={{alignItems:'center',justifyContent:'center',margin:20}}>
              <div style={{ background: '#ECECEC',padding:15 }}>
                  <Title level={2}>User Statistics</Title>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title="Agencies Count" bordered={false}>
                                <Title level={1} style={{color:"#2196f3"}}>
                                    {response &&response.agencies_count }
                                </Title>
                                Agency Clients
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Business Count" bordered={false}>
                            <Title level={1} style={{color:"#2196f3"}}>
                                {response &&response.business_count }
                                </Title>
                                business clients
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Writers Count" bordered={false}>
                                <Title level={1} style={{color:"#2196f3"}}>
                                    {response &&response.writers_count }
                                </Title>
                                active writers
                            </Card>
                        </Col>
                    </Row>
                    <Title level={2}>Order Statistics</Title>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title="Active Orders" bordered={false}>
                                <Title level={1} style={{color:"#2196f3"}}>
                                    {response &&response.active_orders }
                                </Title>
                                Active Orders
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Total Orders" bordered={false}>
                            <Title level={1} style={{color:"#2196f3"}}>
                                {response &&response.orders_count }
                                </Title>
                                Total Orders
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Waiting Review" bordered={false}>
                            <Title level={1} style={{color:"#2196f3"}}>
                                {response &&response.review_queue }
                                </Title>
                                Orders Waiting review
                            </Card>
                        </Col>
                    </Row>
                    <Title level={2}>Revenue Statistics</Title>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title="Overall Orders" bordered={false}>
                                <Title level={1} style={{color:"#2196f3"}}>
                                    {response && currencyFormatter.format(response.orders_grand_sum) }
                                </Title>
                                Overall order revenue
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Completed Orders" bordered={false}>
                            <Title level={1} style={{color:"#2196f3"}}>
                                {response && currencyFormatter.format(response.orders_net_sum )}
                                </Title>
                                Completed Orders revenue
                            </Card>
                        </Col>
                    </Row>
                </div>
        </div>
    )
}

export default Statistics
