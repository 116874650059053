import React,{useState} from 'react'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input';
import Title from 'antd/lib/typography/Title';

import Form from 'antd/lib/form';

import message from 'antd/lib/message'
import { axiosAuthenticated } from '../helper';

const AddAdminUser = () => {
    const [name,setName] = useState()
    const [loading,setIsLoading] = useState(false)
    const [password,setPassword] = useState()
    const [email,setEmail] = useState()

    const handleName = (evt) => {
        const value = evt.target.value;
        setName(value)
    }
    const handleEmail = (evt) => {
        const value = evt.target.value;
        setEmail(value)
    }
    const handlePassword = (evt) => {
        const value = evt.target.value;
        setPassword(value)
    }

    const handleLogin = () => {
        setIsLoading(true)
        axiosAuthenticated()({
            url:'/api/v1/admin/user/add',
            method:'post',
            data:{
                name,email,password
            }
        }).then((res)=>{
            if (res.data.status == true) {
                setIsLoading(false)
                message.success('Admin user added')
                window.location = '/users'
            }else{
                setIsLoading(false)
                message.error("Failed to create admin")
            }
        },(err)=>{
            setIsLoading(false)
            message.error("Failed to create admin")
        })
    }
    return (
        <div className="site-card-wrapper block container-fluid" style={{display:'flex',flexDirection:'column',marginTop:'50px',alignItems:'center',justifyContent:'center',marginLeft:5,marginRight:5}}>
                <Title level={2}>Add Admin</Title>
                <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                >
                      <Form.Item
                        name="name"
                        rules={[
                    
                        {
                            required: true,
                            message: 'Please input admin name',
                        },
                        ]}
                    >
                        <Input placeholder="Admin Name" onChange={handleName}/>
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid email',
                        },
                        {
                            required: true,
                            message: 'Please input your email',
                        },
                        ]}
                    >
                        <Input placeholder="Email Address" onChange={handleEmail}/>
                    </Form.Item>
    
                    <Form.Item
                        name="password"
                    >
                        <Input.Password placeholder="password" onChange={handlePassword}/>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={handleLogin} loading={loading} block type="primary" htmlType="submit">
                          Add New Admin
                        </Button>
                    </Form.Item>
                </Form>
        </div>
    )
}

export default AddAdminUser
